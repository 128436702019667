a {
  color: inherit;
  text-decoration: none;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

ul {
  padding: 0;
  list-style: none;
}

li {
  padding: 0;
}

h3 {
  background: none;
  font-family: "Open Sans", sans-serif;
  color: $black;
  font-size: 20px;
  letter-spacing: normal;
  text-align: center;
  font-weight: normal;
  margin-top: 0;
}

.row {
  @extend .cf;
}

h1,
h2 {
  font-family: "dinmedium";
  font-size: 40px;
  margin: 1em 0;
  color: $black;
}

h1 {
  font-weight: bold;
}

h2 {
  letter-spacing: normal;
}

.half,
._33,
._10,
._90 {
  box-sizing: border-box;
  padding: 0 10px;
  display: inline-block;
  width: 50%;
  position: relative;
  vertical-align: top;
}

._10 {
  width: 10%;
}

._33 {
  width: 33%;
}

._90 {
  width: 90%;
}

#content-right {
  display: none;
}

#breadcrumbs-outer {
  padding: 0;
  height: 0;
  display: none;
}

#content-only {
  border-radius: 15px 15px 0 0;
  padding-top: 20px;
  height: 100%;
}

#content-and-menu {
  position: relative;
  height: 1600px;

  &.withMenu {

    #content-right {
      display: table-cell;
      position: absolute;
      right: 0;
      z-index: 2;
      height: 100%;
      box-shadow: -4px 0 3px #eaeaea;
    }
  }
}

#menuBarOpener {
  background: $higreen;
  color: $black;
  border-radius: 5px 5px 0 0;
  cursor: pointer;
  display: block;
  font-size: 20px;
  line-height: 40px;
  position: absolute;
  right: -95px;
  text-align: center;
  top: 100px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  vertical-align: bottom;
  width: 150px;
  box-shadow: 3px -2px 6px -1px #666;
  text-decoration: none;

  &.fixed {
    position: fixed;
    top: 100px;
    left: calc(50% + 425px); //480 -75 + 20
  }

  &.withMenu {

    &.fixed {
      left: calc(50% + 660px);
    }
  }
}

#content-only ul.breadcrumbs+h2 {
  margin-top: 24px;
}

ul.breadcrumbs {
  font-family: Arial, Helvetica, sans-serif;
  padding: 0;
  margin: 0;
  display: block;
  font-size: 14px;
  color: $gray;

  .backLink {
    font-size: 12px;
    padding: 3px 10px;
    margin-right: 10px;
    border: 1px solid $lgray;
    border-radius: 5px;

    svg {
      fill: $dgray;
      margin: 0 6px 0 0;
      height: 8px;
      width: 8px;
    }
  }

  li {
    display: inline-block;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
}

p {
  line-height: 24px;
  margin: 1em 0;
  padding: 0;
  font-size: 16px;

  &.boxTitle {
    font-size: 20px;
    margin: 10px 0;
    font-family: "dinmedium";

  }
}

.select {
  display: inline-block;
  vertical-align: middle;
  margin: 10px 0 10px 10px;
  position: relative;
  height: 24px;
  width: 160px;
  text-align: left;

  .options {
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid $gray;
    background: $white;
    cursor: pointer;
    position: relative;
    width: 100%;

    &:after {
      border-top: 6px solid $dgray;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      content: " ";
      position: absolute;
      top: 8px;
      right: 7px;
    }

    .option {
      width: 100%;
      box-sizing: border-box;
      display: none;
      padding: 2px 10px;

      &.selected {
        display: block;
      }

      &:first-child {
        border-radius: 5px 5px 0 0;
      }

      &:last-child {
        border-radius: 0 0 5px 5px;
      }
    }
  }

  &[disabled] {
    .options {
      background: #ddd;
      cursor: not-allowed;
    }
  }

  &:hover:not(.justSelected):not([disabled]) .options,
  &.open:not(.justSelected) .options {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    &:after {
      display: none;
    }

    .option {
      display: block;

      &:hover {
        background: $lgreen;
      }

      &.selected {
        background: $lgreen;
      }
    }
  }

  &.scrollable:hover:not(.justSelected):not([disabled]) .options,
  &.scrollable.open:not(.justSelected) .options {
    overflow-y: scroll;
    max-height: 125px;
  }
}

button {
  font-family: inherit;
  background: $higreen;
  border-radius: 5px;
  color: white;
  text-transform: uppercase;
  font-size: 15px;
  padding: 6px 14px;
  display: inline-block;
  font-weight: bold;
  box-sizing: border-box;
  margin: 0 0 4px;
  text-shadow: none;
  box-shadow: 0 4px 0 $dgreen;

  &.dropdown {
    background: $lgray;
    color: $dgray;
    box-shadow: 0 4px 0 $gray;

    svg {
      fill: $dgray;
    }
  }

  &:active {
    box-shadow: none;
    position: relative;
    top: 4px;
  }

  &.endisable {
    position: relative;
    box-shadow: none;
    font-size: 14px;
    width: 110px;
    padding-right: 40px;
    background: $higreen;

    &.dis {
      background: $red;
      padding-right: 14px;
      padding-left: 40px;

      .disable {
        display: inline-block;
      }

      .enable {
        display: none;
      }

      .handle {
        right: calc(100% - 27px);
      }
    }

    &:active {
      top: 0;
    }

    .disable {
      display: none;
    }

    .handle {
      top: 0;
      bottom: 0;
      right: 0;
      border: 1px solid $lgray;
      background: $lgray;
      border-radius: 5px;
      background: linear-gradient(to bottom, #ffffff 0%,#f1f1f1 50%,#e1e1e1 51%,#f6f6f6 100%);
      position: absolute;
      width: 25px;
      transition: right 0.3s;
    }

  }

  svg,
  img {
    width: 15px;
    vertical-align: middle;
    margin-left: 18px;
    position: relative;
    top: -2px;
    fill: white;
    height: 15px;
  }
}

.HI-slider {
  background: $higreen;
  border-radius: 200px;
  position: relative;
  height: 6px;
  margin: 20px 40px 21px;
  cursor: pointer;

  .handle {
    border: 3px solid $black;
    background: $higreen;
    position: absolute;
    top: -8px;
    width: 15px;
    height: 15px;
    border-radius: 20px;

    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

table:not(#content-and-menu) {
  border-collapse: collapse;
  width: 100%;
  margin: 30px 0 40px;
  font-size: 15px;
  border: 1px solid $lgray;
  text-align: left;
  line-height: normal;

  thead {
    font-size: 14px;
    background: $lgray;
    text-transform: uppercase;
    color: $dgray;
    cursor: pointer;
    font-weight: normal;

    th {
      -moz-user-select: -moz-none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;
      user-select: none;
      padding: 12px;
      position: relative;

      &.sort {
        &:before {
          content: "";
          border-top: 7px solid $higreen;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          left: -7px;
          position: absolute;
          top: 20px;
        }

        &.desc:before {
          border-bottom: 7px solid $higreen;
          border-left: 8px solid transparent;
          border-top: none;
          border-right: 8px solid transparent;
          top: 22px;
        }
      }
    }
  }

  tbody {
    font-family: "Open Sans",sans-serif;
    font-size: 15px;

    tr {
      border-bottom: 1px solid $lgray;

      &:hover {
        background: $lgreen;

        .infoBox {
          display: block;
        }
      }

      td {
        padding: 12px;
        position: relative;

        a {
          color: $blue;
          font-weight: bold;
        }

        .blackTick {
          vertical-align: middle;
        }

        .error {
          color: red;
          line-height: 26px;
        }

        &:last-child {
          &:hover {
            position: relative;

            ul {
              position: absolute;
              top: 50px;
              display: block;
              z-index: 1;
              left: 15px;
            }
          }

          ul {
            display: none;
            background: white;
            border: 2px solid $lgray;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 14px;
            font-family: "dinmedium";

            li {
              width: 230px;
              display: block;
              padding: 10px;

              a {
                cursor: pointer;
                color: inherit;
                display: block;
              }

              img {
                margin-right: 4px;
                vertical-align: middle;
                width: 30px;
              }
            }
          }
        }
      }
    }
  }
}

#popup-wrapper {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: none;

  .mask {
    background: $white;
    opacity: 0.75;
    width: 100%;
    height: 100%;
  }

  > div:not(.mask) {
    position: absolute;
    border-radius: 10px;
    box-shadow: 5px 5px 15px gray;
    width: 600px;
    box-sizing: border-box;
    padding: 0;
    border: 4px solid $higreen;
    background: white;
    top: calc(35% - 88px);
    left: calc(50% - 300px);
    text-align: center;
    display: none;

    button {
      padding: 10px 10px 7px;
      height: auto;
    }
  }
}
