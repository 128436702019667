.hybrid2_ui {
  @import 'constants'; //Color definitions
  @import 'shared';
  color: $black;
  font-family: 'Open Sans', sans-serif;

  #content-only h2 {

    //selector altered to override external rules
    font-size: 22px;
    margin: 1em 0 0.8em !important; //important to override extarnal rules
  }

  #noBUps {
    color: $higreen;
    cursor: pointer;
  }

  .user_help {
    color: $higreen;
    margin: -25px 0 0;
    font-size: 12px;
    text-align: right;
  }

  .upgrade-only {
    display: none;
  }

  &.is-upgrade {
    .upgrade-only {
      display: initial;
    }

    .order-only {
      display: none;
    }
  }

  .warning {
    color: #cd0b0b;
  }

  .success {
    color: $dgreen;
  }

  small {
    font-size: 0.8em;
  }

  #conf-steps {
    background: $vlgray;
    border: 1px solid $lgray;
    margin-bottom: 40px;

    div.progress {
      margin: -1px -1px 0;
      height: 8px;
      background: $gray;

      div {
        background: $higreen;
        width: 0;
        height: 100%;
        transition: width 1s ease;
      }
    }

    ul.steps {
      width: 100%;

      li {
        text-align: center !important; //need to overwrite some external rule
        display: inline-block;
        width: 20%;
        position: relative;
        cursor: pointer;

        &.toChoose {
          opacity: 0.3;
          cursor: not-allowed;
        }

        &:not(:first-child) {
          width: calc(20% - 1px);
          border-left: 1px solid $lgray;
        }

        &.current:after {
          content: ' ';
          position: absolute;
          bottom: -20px;
          left: calc(50% - 90px);
          border-top: 20px solid $higreen;
          border-left: 90px solid transparent;
          border-right: 90px solid transparent;
        }

        p.stepNo {
          font-size: 25px;
          font-weight: bold;
          color: $higreen;
          margin: 15px 0;
        }

        p.title {
          font-weight: bold;
          margin-bottom: 1.4em;
        }

        &.chosen {
          vertical-align: top;

          img {
            width: 30px;
            margin: 2px 0;
          }

          p.value {
            margin: 1px 0;
            font-size: 12px;
            text-transform: uppercase;
          }

          p.step {
            color: $higreen;
            font-weight: bold;
            margin: 6px 0 4px;
          }

          .vpsName {
            font-size: 14px;
            font-weight: bold;
            line-height: normal;
            margin: 4px 0;
          }

          .slotsNo {
            font-size: 14px;
            font-weight: bold;
            line-height: normal;
            margin: 9px 0;
          }

          table {
            border: none;
            margin: 0;

            tr:hover {
              background: none;
            }

            tr.top td {
              padding-bottom: 0;
            }

            tr,
            td {
              border: none;
            }

            tbody td {
              text-align: center;
              font-size: 12px;
              padding: 2px;
            }
          }
        }
      }
    }
  }

  #addingToBasket,
  #addedToBasket,
  #upgrading,
  #upgraded {
    padding: 40px 0;
    text-align: center;
    display: none;

    p {
      font-weight: bold;
      font-size: 25px;
    }

    button {
      margin: 40px;
      font-size: 20px;
    }
  }

  .hybrid_options {
    display: none;
  }

  ul#review {
    padding-left: 30px;
    font-size: 16px;
    list-style: disc;
    margin-top: 30px;
    font-family: inherit;

    li {
      margin: 10px 0;
    }
  }

  p.buttonWrapper {
    text-align: center;
  }

  ul.options {
    text-align: center;
    width: 100%;

    > li {
      vertical-align: top;
      text-align: center !important; //need to overwrite some external rule
      background: $vlgray;
      border: 1px solid $lgray;
      display: inline-block;
      margin: 5px 0;

      img {
        height: 35px;
        width: 35px;
      }

      p.title {
        font-weight: bold;
        font-size: 20px;
        margin: 0;
        padding-bottom: 15px;
        border-bottom: 1px solid $lgray;
      }

      p.description {
        font-size: 14px;
        margin-bottom: 8px;
      }

      p.option_price {
        color: $gray;
        font-size: 13px;
        font-style: italic;
      }

      p.bigPrice {
        color: $higreen;
        font-weight: bold;
        font-size: 22px;
        line-height: 1.4;
        margin-bottom: 40px;
      }

      p.bigPrice+.option_price {
        margin: -40px 0 0;
      }

      .features {
        margin: 20px 0 25px;

        p {
          margin: 15px 0;
          line-height: 20px;
          font-size: 18px;

          span,
          select {
            line-height: 20px;
            color: $higreen;
            margin: 0 5px;
            font-size: 20px;
            font-weight: bold;
          }

          label {
            display: block;
            margin: 15px 0;
          }
        }

        select {
          background: white;
          border-radius: 5px 0 0 5px;
          border: none;
          text-transform: uppercase;
          text-align: right;
          padding-right: 8px;
          font-family: inherit;
        }
      }

      button {
        width: 150px;
        font-size: 16px;
      }

      p.buttons {
        margin-bottom: 0;

        button {
          width: auto;

          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }

    .option-disabled {
      opacity: 0.5;
    }

    .option-current {
      border-color: #c0c0c0;
      position: relative;

      &:after {
        content: 'current';
        background: #818181;
        color: #fff;
        padding: 5px;
        border-radius: 5px;
        position: absolute;
        top: 5px;
        left: 5px;
      }
    }

    &.three {
      $margin: 20px;
      $padding: 20px;

      li {
        padding: $padding;
        width: calc(33.333% - #{$padding*2+$margin*2/3+2});

        &:not(:first-child) {
          margin-left: $margin;
        }

        &:nth-child(3n+1) {
          margin-left: 0;
        }
      }
    }

    &.two {
      $margin: 40px;
      $padding: 30px;

      li {
        padding: 15px $padding $padding;
        width: calc(50% - #{$padding*2+$margin*.5+2});

        &:not(:first-child) {
          margin-left: $margin;
        }
      }
    }

    &.one {
      margin-bottom: 30px;
      $margin: 0;
      $padding: 50px;

      > li {
        padding: 15px $padding 30px;
        width: calc(100% - #{$padding*2+2});
      }
    }

    &.twelve {
      $margin: 10px;
      $padding: 20px;

      li {
        padding: $padding;
        width: calc(25% - 52px);
        margin: 5px;

        p.title {
          overflow-x: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          white-space: nowrap;
        }

        p.option_price {
          margin: 10px 0;
        }
      }
    }
  }

  .currentServer img {
    margin: auto;
    display: block;
  }

  .currentSpecs {
    vertical-align: top;
    text-align: center !important; //need to overwrite some external rule
    background: $vlgray;
    border: 1px solid $lgray;
    margin: 10px auto;
    padding: 15px 30px;
    width: calc(33.333% - 55.3333px);

    p.title {
      font-weight: bold;
      font-size: 20px;
      margin: 0;
      padding-bottom: 15px;
      border-bottom: 1px solid $lgray;
    }

    p.description {
      font-size: 14px;
      margin-bottom: 8px;
    }

    p.option_price {
      color: $gray;
      font-size: 13px;
      font-style: italic;
    }

    p.bigPrice {
      color: $higreen;
      font-weight: bold;
      font-size: 40px;
      margin-bottom: 60px;
    }

    p.bigPrice+.option_price {
      margin: -40px 0 0;
    }

    .features {
      margin: 20px 0 25px;

      p {
        margin: 15px 0;
        line-height: 20px;
        font-size: 18px;

        span,
        select {
          line-height: 20px;
          color: $higreen;
          margin: 0 5px;
          font-size: 20px;
          font-weight: bold;
        }

        label {
          display: block;
          margin: 15px 0;
        }
      }

      select {
        background: white;
        border-radius: 5px 0 0 5px;
        border: none;
        text-transform: uppercase;
        text-align: right;
        padding-right: 8px;
        font-family: inherit;
      }
    }
  }

  // VPS styles
  .vps_options {
    .options {
      > li {
        p.option_price {
          font-size: 15px;
          margin: 10px;
        }

        .features {
          margin-top: 10px;
          margin-bottom: 20px;

          label {
            margin: 10px 0;
          }

          p {
            margin-top: 10px;
            margin-bottom: 10px;
          }
        }

        button {
          width: 100%;
        }
      }
    }
  }
}
